@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;800&display=swap');

body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100vh;
  overflow: hidden;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--root-background-color);
  background-image: var(--root-background-image);
  background-size: var(--root-background-size);
  background-attachment: var(--root-background-attachment);
}

@media screen and (orientation : portrait) {
  #root {
    background-position: var(--root-background-position-portrait);
  }
}

@media screen and (orientation : landscape) {
  #root {
    background-position: var(--root-background-position-landscape);
  }
}