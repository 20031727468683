button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.25rem 2rem;
  box-sizing: border-box;
  background-color: rgb(27, 136, 226);
  border: solid 4px rgb(240, 248, 255);
  box-shadow: 0 2px 4px 1px rgba(0,0,0,0.5);
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition-duration: 0.3s;
}

button:focus {
  outline: none;
}

button:active,
button:disabled {
  box-shadow: none;
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.3) 100%
    );
}