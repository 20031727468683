.container {
  width: 100%;
  height: 20px;
  box-shadow:0px 0px 8px 3px #ccc inset;
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  background: linear-gradient(
    rgb(144, 214, 252),
    rgb(0, 165, 255),
    rgb(144, 214, 252)
  );
  height: 100%;
}