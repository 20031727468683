.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  contain: layout size style;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  z-index: 0;
}

/*
 * header
 */

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.header > img {
  width: auto;
  height: 30vh;
  animation-name: img-animation;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.5s;
}

@keyframes img-animation {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

@media screen and (min-height : 554px) {
  .header > img {
    height: 35vh;
  }
}

@media screen and (min-height : 628px) {
  .header > img {
    height: 40vh;
  }
}

/*
 * content
 */

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: min(90%, 640px);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  z-index: 1;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.item > label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow:
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333;
  color: #fff;
}

.item:nth-child(1) > label {
  color: rgb(241, 91, 200);
}

.item:nth-child(2) > label {
  color: rgb(111, 207, 217);
}

.item:nth-child(3) > label {
  color: rgb(252, 227, 112);
}

.item > label > input {
  display: block;
  width: min(180px, 60vw);
  padding: 0.25rem;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border-style: inset;
  border-color: rgb(195, 195, 195);
  text-align: center;
  user-select: auto;
  outline: none;
}

.item:nth-child(1) > label > input:focus {
  box-shadow: 0 0 0 2px rgb(241, 91, 200);
}

.item:nth-child(2) > label > input:focus {
  box-shadow: 0 0 0 2px rgb(111, 207, 217);
}

.item:nth-child(3) > label > input:focus {
  box-shadow: 0 0 0 2px rgb(252, 227, 112);
}

.item > button {
  width: min(60vw, 200px);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.loading {
  --loading-size: 1.5rem;
  display: inline-block;
  width: var(--loading-size);
  min-width: var(--loading-size);
  height: var(--loading-size);
  min-height: var(--loading-size);
  position: absolute;
  top: 50%;
  right: calc(var(--loading-size)/3);
  transform: translateY(-50%);
}

.loading .circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loading .circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 50%;
  animation: circleFadeDelay 1.2s infinite ease-in-out both;
}

.loading .circle:nth-child(2) {
  transform: rotate(30deg);
}
.loading .circle:nth-child(3) {
  transform: rotate(60deg);
}
.loading .circle:nth-child(4) {
  transform: rotate(90deg);
}
.loading .circle:nth-child(5) {
  transform: rotate(120deg);
}
.loading .circle:nth-child(6) {
  transform: rotate(150deg);
}
.loading .circle:nth-child(7) {
  transform: rotate(180deg);
}
.loading .circle:nth-child(8) {
          transform: rotate(210deg);
}
.loading .circle:nth-child(9) {
  transform: rotate(240deg);
}
.loading .circle:nth-child(10) {
  transform: rotate(270deg);
}
.loading .circle:nth-child(11) {
          transform: rotate(300deg); 
}
.loading .circle:nth-child(12) {
  transform: rotate(330deg); 
}
.loading .circle:nth-child(2):before {
  animation-delay: -1.1s; 
}
.loading .circle:nth-child(3):before {
  animation-delay: -1s; 
}
.loading .circle:nth-child(4):before {
  animation-delay: -0.9s; 
}
.loading .circle:nth-child(5):before {
  animation-delay: -0.8s; 
}
.loading .circle:nth-child(6):before {
  animation-delay: -0.7s; 
}
.loading .circle:nth-child(7):before {
  animation-delay: -0.6s; 
}
.loading .circle:nth-child(8):before {
  animation-delay: -0.5s; 
}
.loading .circle:nth-child(9):before {
  animation-delay: -0.4s;
}
.loading .circle:nth-child(10):before {
  animation-delay: -0.3s;
}
.loading .circle:nth-child(11):before {
  animation-delay: -0.2s;
}
.loading .circle:nth-child(12):before {
  animation-delay: -0.1s;
}

@keyframes circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}

/*
 * footer
 */
.footer {
  width: 100%;
  margin-top: 5%;
  padding: 0.25rem;
  padding-bottom: calc(0.25rem + env(safe-area-inset-bottom));
  box-sizing: border-box;
  font-size: 0.8rem;
  text-align: center;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.05);
}

/*
 * toast
 */
 .toast {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: min(90vw, 980px * 0.9);
  padding: 1rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 1rem;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  z-index: 2;
}

.toast > div:nth-child(2) {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.toast > div:nth-child(2) > button {
  width: min(8rem, 35vw);
  padding: 0.25rem 0;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  transition-duration: 0.3s;
}

.toast > div:nth-child(2) > button:active {
  color: #aaa;
}

.showToast {
  transform: translate(-50%, 0);
  animation-name: show-toast;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

@keyframes show-toast {
  from {
    transform: translate(-50%, calc(-100% - 1rem));
  }
  to {
    transform: translate(-50%, 0);
  }
}

.hideToast {
  transform: translate(-50%, calc(-100% - 1rem));
  animation-name: hide-toast;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

@keyframes hide-toast {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, calc(-100% - 1rem));
  }
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 13/9) and (max-height : 568px) {
  .container {
    flex-direction: row;
    padding-bottom: 1.7rem;
  }

  .header > img {
    width: 55vw;
    height: auto;
  }

  .content {
    margin-right: 1rem;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 0;
    padding-bottom: 0.25rem;
  }
}

@media screen and (max-aspect-ratio: 13/9) and (max-height: 414px) {
  .header > img {
    height: 0;
  }
}