.container {
  --panel-border-color: rgb(252, 231, 186);
  --panel-background-color: rgb(240, 248, 255);
  --panel-background-image:
    linear-gradient(
      to bottom, 
      rgb(231, 233, 235) 0%,
      rgb(255, 255, 255) 30%,
      rgb(255, 255, 255) 70%,
      rgb(231, 233, 235) 100%
    );
  --panel-border-radius: 0.5rem;
  --panel-box-shadow: 0 2px 4px 1px rgba(0,0,0,0.5);

  --yes-color: rgb(27, 136, 226);
  --no-color: rgb(226, 50, 27);

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  contain: layout size style;
  z-index: 0;
}

/*
 * header
 */

.header {
  width: min(100%, 980px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.entry {
  border-left: solid 1px var(--panel-border-color);
  padding-right: 1rem;
  overflow: hidden;
}

.entry > span {
  display: inline-block;
  background-color: var(--panel-background-color);
  border-right: solid 3px var(--panel-border-color);
  border-bottom: solid 3px var(--panel-border-color);
  border-bottom-right-radius: var(--panel-border-radius);
  box-shadow: 0 1px 1px rgba(0,0,0,0.5);
  box-sizing: border-box;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2.75rem;
  padding-right: 1rem;
  margin-left: -2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(65, 35, 5);
  transform: skewX(-25deg);
}

.entry > span > small {
  margin-top: calc(1.6rem * 0.25 - 0.2rem);
  font-size: 50%;
}

@media screen and (min-width:981px) {
  .entry {
    border-left: none;
  }

  .entry > span {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2px;
    border-radius: var(--panel-border-radius);
    transform: none;
  }
}

.status {
  width: min(65vw, calc(980px * 0.65 * 0.5));
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.score, .ranking {
  position: relative;
  width: min(30vw, calc(980px * 0.3 * 0.5));
  box-sizing: border-box;
  background-color: var(--panel-background-color);
  border: solid 3px var(--panel-border-color);
  border-radius: 2rem;
  box-shadow: 0 2px 1px rgba(0,0,0,0.5);
  padding: 2px 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 1rem;
  text-shadow:
    1px 1px 0 #333, -1px -1px 0 #333,
    -1px 1px 0 #333, 1px -1px 0 #333,
    0px 1px 0 #333,  0-1px 0 #333,
    -1px 0 0 #333, 1px 0 0 #333;
  color: #fff;
}

.score::before, .ranking::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.score::before {
  width: 1.4rem;
  height: calc(1.4rem * 82 / 85);
  background-image: url("/img/icon_point.png");
}

.ranking::before {
  width: 1.4rem;
  height: calc(1.4rem * 110 / 125);
  background-image: url("/img/icon_crown.png");
}

/*
 * content
 */

.content {
  width: min(calc(100% - 2rem), calc(980px - 2rem));
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  background-color: var(--panel-background-color);
  border: solid 6px var(--panel-border-color);
  border-radius: var(--panel-border-radius);
  box-shadow: var(--panel-box-shadow);
}

.title {
  position: relative;
  width: calc(100% + 2px);
  margin-top: -2px;
  background-color: rgb(123, 55, 0);
  border: outset 1px rgb(248, 178, 124);
  border-radius: 0.5rem;
  box-shadow: 0 2px 1px rgba(0,0,0,0.5);
  font-size: 1.2rem;
  text-align: center;
  text-shadow: 0px 2px 3px rgb(40, 40, 40);
  color: #fff;
}

.title::before, .title::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  border: outset 1px rgb(123, 55, 0);
  background-color: rgb(172, 106, 0);
  box-shadow: 0 2px 1px rgba(0,0,0,0.5);
}

.title::before {
  left: 0.5rem;
}

.title::after {
  right: 0.5rem;
}

.point {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 0.5rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
  text-shadow:
    1px 1px 0 #333, -1px -1px 0 #333,
    -1px 1px 0 #333, 1px -1px 0 #333,
    0px 1px 0 #333,  0-1px 0 #333,
    -1px 0 0 #333, 1px 0 0 #333;
  color: #fff;
}

.point::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: calc(1rem * 82 / 85);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/img/icon_point.png");
}

.source {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-shadow:
    1px 1px 0 #777, -1px -1px 0 #777,
    -1px 1px 0 #777, 1px -1px 0 #777,
    0px 1px 0 #777,  0 -1px 0 #777,
    -1px 0 0 #777, 1px 0 0 #777;
  color: rgb(240, 230, 30);
}

.question {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font-size: 1.6rem;
}

.question > img {
  border-radius: 5%;
  margin: 0 0 1rem 0;
  width: min(160px, 35vw);
  height: min(160px, 35vw);
  object-fit: contain;
}

.countdownContainer {
  width: calc(100% - 0.25rem * 2);
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

@media screen and (orientation : portrait) and (min-height:568px) {
  .question {
    padding: 10% 1rem;
  }
}

@media screen and (orientation : landscape) {
  .question {
    flex-direction: row;
    padding: 0 1rem;
  }

  .question > img {
    margin: 0 1rem 0 0;
    width: min(160px, 30vh);
    height: min(160px, 30vh);  
  }
}

@media screen and (orientation : landscape) and (min-width:981px) and (min-height:569px) {
  .question {
    padding: 1rem;
    flex-direction: column;
  }

  .question > img {
    width: 40vh;
    height: 40vh;
  }
}

/*
 * footer
 */

.footer {
  width: min(100%, 980px);
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.footer > button {
  --yes-border: outset 4px var(--yes-color);
  --no-border: outset 4px var(--no-color);
  --disabled-color: rgb(173, 173, 173);
  --disabled-border: outset 4px var(--panel-border-color);

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  width: 45%;
  height: min(85%, 96px);
  background-color: var(--panel-background-color);
  background-image: var(--panel-background-image);
  border: outset 4px var(--panel-border-color);
  border-radius: 5rem;
  box-shadow:
    var(--panel-box-shadow),
    inset 0 1px 2px 1px rgba(0,0,0,0.5);
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 2px 3px rgb(0,0,0);
  transition-duration: 0.3s;
}

.item > button:focus {
  outline: none;
}

.footer > button:active,
.footer > button.selected,
.footer > button:disabled {
  box-shadow: none;
  text-shadow: none;
}

.footer > button.yes {
  color: var(--yes-color);
  border: var(--yes-border);
}

.footer > button.no {
  color: var(--no-color);
  border: var(--no-border);
}

.footer > button.yes:disabled,
.footer > button.no:disabled {
  color: var(--disabled-color);
  border: var(--disabled-border);
}

/*
 * overlay
 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.answer,
.lottery,
.end {
  width: min(80%, 320px);
  padding: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--panel-background-color);
  background-image: var(--panel-background-image);
  border: outset 4px var(--panel-border-color);
  border-radius: 1rem;
  box-shadow:
    var(--panel-box-shadow),
    inset 0 1px 2px 1px rgba(0,0,0,0.5);
  box-sizing: border-box;
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 1px 2px rgb(0,0,0);
  animation-name: show-panel;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  z-index: 2;
}

@keyframes show-panel {
  from {
    transform: translateY(-40px);
    opacity:0;
  }
  to {
    transform: none;
    opacity:1;
  }
}

.answer p {
  margin-block-start: 1rem;
  margin-block-end: 0;
}

.answer img {
  display: block;
  margin: 0.5rem;
  filter: drop-shadow(0 2px 2px rgb(0,0,0));
}

.answer.right {
  color: rgb(45, 211, 111);
}

.answer.wrong {
  color: rgb(148, 66, 212);
}

.lottery.win {
  animation-name: show-win-panel;
}

@keyframes show-win-panel {
  from {
    transform: scale(0.5) rotate(720deg);
    opacity:0;
  }
  to {
    transform: none;
    opacity:1;
  }
}

.lottery.win p,
.lottery.won p,
.lottery.lose p {
  width: 100%;
  margin-block-start: 1rem;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.lottery.win p,
.lottery.won p {
  color: rgb(226, 50, 27);
  border-top: dotted 10px #f58e08;
  border-bottom: dotted 10px #f58e08;
}

.lottery.win p {
  margin-block-end: 2rem;
}

.lottery.won p,
.lottery.lose p {
  margin-block-end: 1rem;
}

.lottery.win p > span,
.lottery.won p > span {
  display: inline-block;
  font-style: normal;
  font-size: 48px;
  margin-right: 0.5rem;
}

.lottery.win .winningNumber {
  padding-left: 1rem;
  padding-right: 1rem;
  border: solid 4px rgb(27, 136, 226);
  border-radius: 1rem;
  font-size: 2rem;
  color: rgb(27, 136, 226);
}

.lottery.lose > p {
  border-top: dashed 10px rgb(27, 136, 226);
  border-bottom: dashed 10px rgb(27, 136, 226);
  color: rgb(27, 136, 226);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.lottery.lose p > span {
  display: inline-block;
  font-style: normal;
  font-size: 48px;
  font-size: 1.5rem;
}

.end p {
  color: #ffab3c;
}

.end > button {
  width: min(50vw, 200px);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
