:root {
  --root-background-color: #fac043;
  --root-background-image:
    radial-gradient(
      circle at center,
      rgba(255, 255, 255, 1.0) 0,
      rgba(255, 255, 255, 0.0) 20%
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 12deg,
    #ffab3c 12deg,
    #ffab3c 16deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 15deg,
      #ffab3c 15deg,
      #ffab3c 28deg
    );
  --root-background-size: max(200vw,200vh) max(200vw,200vh);
  --root-background-attachment: scroll;
  --root-background-position-portrait: 50% 75%;
  --root-background-position-landscape: 50% 50%;
}