@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;800&display=swap);
body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100vh;
  overflow: hidden;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--root-background-color);
  background-image: var(--root-background-image);
  background-size: var(--root-background-size);
  background-attachment: var(--root-background-attachment);
}

@media screen and (orientation : portrait) {
  #root {
    background-position: var(--root-background-position-portrait);
  }
}

@media screen and (orientation : landscape) {
  #root {
    background-position: var(--root-background-position-landscape);
  }
}
:root {
  --root-background-color: #fac043;
  --root-background-image:
    radial-gradient(
      circle at center,
      rgba(255, 255, 255, 1.0) 0,
      rgba(255, 255, 255, 0.0) 20%
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 12deg,
    #ffab3c 12deg,
    #ffab3c 16deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 15deg,
      #ffab3c 15deg,
      #ffab3c 28deg
    );
  --root-background-size: max(200vw,200vh) max(200vw,200vh);
  --root-background-attachment: scroll;
  --root-background-position-portrait: 50% 75%;
  --root-background-position-landscape: 50% 50%;
}
button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.25rem 2rem;
  box-sizing: border-box;
  background-color: rgb(27, 136, 226);
  border: solid 4px rgb(240, 248, 255);
  box-shadow: 0 2px 4px 1px rgba(0,0,0,0.5);
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  transition-duration: 0.3s;
}

button:focus {
  outline: none;
}

button:active,
button:disabled {
  box-shadow: none;
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.3) 100%
    );
}
.Entry_container__PYx56 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  contain: layout size style;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  z-index: 0;
}

/*
 * header
 */

.Entry_header__2yKVE {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Entry_header__2yKVE > img {
  width: auto;
  height: 30vh;
  -webkit-animation-name: Entry_img-animation__mU7SB;
          animation-name: Entry_img-animation__mU7SB;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

@-webkit-keyframes Entry_img-animation__mU7SB {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

@keyframes Entry_img-animation__mU7SB {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

@media screen and (min-height : 554px) {
  .Entry_header__2yKVE > img {
    height: 35vh;
  }
}

@media screen and (min-height : 628px) {
  .Entry_header__2yKVE > img {
    height: 40vh;
  }
}

/*
 * content
 */

.Entry_content__35p5u {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: min(90%, 640px);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  z-index: 1;
}

.Entry_item__10E2b {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Entry_item__10E2b > label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow:
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333,
    0 0 3px #333;
  color: #fff;
}

.Entry_item__10E2b:nth-child(1) > label {
  color: rgb(241, 91, 200);
}

.Entry_item__10E2b:nth-child(2) > label {
  color: rgb(111, 207, 217);
}

.Entry_item__10E2b:nth-child(3) > label {
  color: rgb(252, 227, 112);
}

.Entry_item__10E2b > label > input {
  display: block;
  width: min(180px, 60vw);
  padding: 0.25rem;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border-style: inset;
  border-color: rgb(195, 195, 195);
  text-align: center;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  outline: none;
}

.Entry_item__10E2b:nth-child(1) > label > input:focus {
  box-shadow: 0 0 0 2px rgb(241, 91, 200);
}

.Entry_item__10E2b:nth-child(2) > label > input:focus {
  box-shadow: 0 0 0 2px rgb(111, 207, 217);
}

.Entry_item__10E2b:nth-child(3) > label > input:focus {
  box-shadow: 0 0 0 2px rgb(252, 227, 112);
}

.Entry_item__10E2b > button {
  width: min(60vw, 200px);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.Entry_loading__3tx7_ {
  --loading-size: 1.5rem;
  display: inline-block;
  width: var(--loading-size);
  min-width: var(--loading-size);
  height: var(--loading-size);
  min-height: var(--loading-size);
  position: absolute;
  top: 50%;
  right: calc(var(--loading-size)/3);
  transform: translateY(-50%);
}

.Entry_loading__3tx7_ .Entry_circle__1UoiU {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Entry_loading__3tx7_ .Entry_circle__1UoiU:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 50%;
  -webkit-animation: Entry_circleFadeDelay__1CCZF 1.2s infinite ease-in-out both;
          animation: Entry_circleFadeDelay__1CCZF 1.2s infinite ease-in-out both;
}

.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(2) {
  transform: rotate(30deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(3) {
  transform: rotate(60deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(4) {
  transform: rotate(90deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(5) {
  transform: rotate(120deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(6) {
  transform: rotate(150deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(7) {
  transform: rotate(180deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(8) {
          transform: rotate(210deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(9) {
  transform: rotate(240deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(10) {
  transform: rotate(270deg);
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(11) {
          transform: rotate(300deg); 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(12) {
  transform: rotate(330deg); 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(2):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(3):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(4):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(5):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(6):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(7):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(8):before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(9):before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(10):before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(11):before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.Entry_loading__3tx7_ .Entry_circle__1UoiU:nth-child(12):before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes Entry_circleFadeDelay__1CCZF {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}

@keyframes Entry_circleFadeDelay__1CCZF {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}

/*
 * footer
 */
.Entry_footer__zlYse {
  width: 100%;
  margin-top: 5%;
  padding: 0.25rem;
  padding-bottom: calc(0.25rem + env(safe-area-inset-bottom));
  box-sizing: border-box;
  font-size: 0.8rem;
  text-align: center;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.05);
}

/*
 * toast
 */
 .Entry_toast__2k1xR {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: min(90vw, 980px * 0.9);
  padding: 1rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 1rem;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  z-index: 2;
}

.Entry_toast__2k1xR > div:nth-child(2) {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.Entry_toast__2k1xR > div:nth-child(2) > button {
  width: min(8rem, 35vw);
  padding: 0.25rem 0;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  transition-duration: 0.3s;
}

.Entry_toast__2k1xR > div:nth-child(2) > button:active {
  color: #aaa;
}

.Entry_showToast__30b0k {
  transform: translate(-50%, 0);
  -webkit-animation-name: Entry_show-toast__1FLC2;
          animation-name: Entry_show-toast__1FLC2;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

@-webkit-keyframes Entry_show-toast__1FLC2 {
  from {
    transform: translate(-50%, calc(-100% - 1rem));
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes Entry_show-toast__1FLC2 {
  from {
    transform: translate(-50%, calc(-100% - 1rem));
  }
  to {
    transform: translate(-50%, 0);
  }
}

.Entry_hideToast__2pBo_ {
  transform: translate(-50%, calc(-100% - 1rem));
  -webkit-animation-name: Entry_hide-toast__2OCyP;
          animation-name: Entry_hide-toast__2OCyP;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

@-webkit-keyframes Entry_hide-toast__2OCyP {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, calc(-100% - 1rem));
  }
}

@keyframes Entry_hide-toast__2OCyP {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, calc(-100% - 1rem));
  }
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 13/9) and (max-height : 568px) {
  .Entry_container__PYx56 {
    flex-direction: row;
    padding-bottom: 1.7rem;
  }

  .Entry_header__2yKVE > img {
    width: 55vw;
    height: auto;
  }

  .Entry_content__35p5u {
    margin-right: 1rem;
  }

  .Entry_footer__zlYse {
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 0;
    padding-bottom: 0.25rem;
  }
}

@media screen and (max-aspect-ratio: 13/9) and (max-height: 414px) {
  .Entry_header__2yKVE > img {
    height: 0;
  }
}
.Game_container__h5pWX {
  --panel-border-color: rgb(252, 231, 186);
  --panel-background-color: rgb(240, 248, 255);
  --panel-background-image:
    linear-gradient(
      to bottom, 
      rgb(231, 233, 235) 0%,
      rgb(255, 255, 255) 30%,
      rgb(255, 255, 255) 70%,
      rgb(231, 233, 235) 100%
    );
  --panel-border-radius: 0.5rem;
  --panel-box-shadow: 0 2px 4px 1px rgba(0,0,0,0.5);

  --yes-color: rgb(27, 136, 226);
  --no-color: rgb(226, 50, 27);

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  contain: layout size style;
  z-index: 0;
}

/*
 * header
 */

.Game_header__1Zc8b {
  width: min(100%, 980px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Game_entry__208Ad {
  border-left: solid 1px var(--panel-border-color);
  padding-right: 1rem;
  overflow: hidden;
}

.Game_entry__208Ad > span {
  display: inline-block;
  background-color: var(--panel-background-color);
  border-right: solid 3px var(--panel-border-color);
  border-bottom: solid 3px var(--panel-border-color);
  border-bottom-right-radius: var(--panel-border-radius);
  box-shadow: 0 1px 1px rgba(0,0,0,0.5);
  box-sizing: border-box;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2.75rem;
  padding-right: 1rem;
  margin-left: -2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(65, 35, 5);
  transform: skewX(-25deg);
}

.Game_entry__208Ad > span > small {
  margin-top: calc(1.6rem * 0.25 - 0.2rem);
  font-size: 50%;
}

@media screen and (min-width:981px) {
  .Game_entry__208Ad {
    border-left: none;
  }

  .Game_entry__208Ad > span {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2px;
    border-radius: var(--panel-border-radius);
    transform: none;
  }
}

.Game_status__3fYsr {
  width: min(65vw, calc(980px * 0.65 * 0.5));
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Game_score__3uDX8, .Game_ranking__pO0k- {
  position: relative;
  width: min(30vw, calc(980px * 0.3 * 0.5));
  box-sizing: border-box;
  background-color: var(--panel-background-color);
  border: solid 3px var(--panel-border-color);
  border-radius: 2rem;
  box-shadow: 0 2px 1px rgba(0,0,0,0.5);
  padding: 2px 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 1rem;
  text-shadow:
    1px 1px 0 #333, -1px -1px 0 #333,
    -1px 1px 0 #333, 1px -1px 0 #333,
    0px 1px 0 #333,  0-1px 0 #333,
    -1px 0 0 #333, 1px 0 0 #333;
  color: #fff;
}

.Game_score__3uDX8::before, .Game_ranking__pO0k-::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.Game_score__3uDX8::before {
  width: 1.4rem;
  height: calc(1.4rem * 82 / 85);
  background-image: url("/img/icon_point.png");
}

.Game_ranking__pO0k-::before {
  width: 1.4rem;
  height: calc(1.4rem * 110 / 125);
  background-image: url("/img/icon_crown.png");
}

/*
 * content
 */

.Game_content__3JJI_ {
  width: min(calc(100% - 2rem), calc(980px - 2rem));
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  background-color: var(--panel-background-color);
  border: solid 6px var(--panel-border-color);
  border-radius: var(--panel-border-radius);
  box-shadow: var(--panel-box-shadow);
}

.Game_title__2en9Y {
  position: relative;
  width: calc(100% + 2px);
  margin-top: -2px;
  background-color: rgb(123, 55, 0);
  border: outset 1px rgb(248, 178, 124);
  border-radius: 0.5rem;
  box-shadow: 0 2px 1px rgba(0,0,0,0.5);
  font-size: 1.2rem;
  text-align: center;
  text-shadow: 0px 2px 3px rgb(40, 40, 40);
  color: #fff;
}

.Game_title__2en9Y::before, .Game_title__2en9Y::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  border: outset 1px rgb(123, 55, 0);
  background-color: rgb(172, 106, 0);
  box-shadow: 0 2px 1px rgba(0,0,0,0.5);
}

.Game_title__2en9Y::before {
  left: 0.5rem;
}

.Game_title__2en9Y::after {
  right: 0.5rem;
}

.Game_point__2FNUO {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 0.5rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
  text-shadow:
    1px 1px 0 #333, -1px -1px 0 #333,
    -1px 1px 0 #333, 1px -1px 0 #333,
    0px 1px 0 #333,  0-1px 0 #333,
    -1px 0 0 #333, 1px 0 0 #333;
  color: #fff;
}

.Game_point__2FNUO::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: calc(1rem * 82 / 85);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/img/icon_point.png");
}

.Game_source__2AbXj {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-shadow:
    1px 1px 0 #777, -1px -1px 0 #777,
    -1px 1px 0 #777, 1px -1px 0 #777,
    0px 1px 0 #777,  0 -1px 0 #777,
    -1px 0 0 #777, 1px 0 0 #777;
  color: rgb(240, 230, 30);
}

.Game_question__2TkJ0 {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font-size: 1.6rem;
}

.Game_question__2TkJ0 > img {
  border-radius: 5%;
  margin: 0 0 1rem 0;
  width: min(160px, 35vw);
  height: min(160px, 35vw);
  object-fit: contain;
}

.Game_countdownContainer__1IB9R {
  width: calc(100% - 0.25rem * 2);
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

@media screen and (orientation : portrait) and (min-height:568px) {
  .Game_question__2TkJ0 {
    padding: 10% 1rem;
  }
}

@media screen and (orientation : landscape) {
  .Game_question__2TkJ0 {
    flex-direction: row;
    padding: 0 1rem;
  }

  .Game_question__2TkJ0 > img {
    margin: 0 1rem 0 0;
    width: min(160px, 30vh);
    height: min(160px, 30vh);  
  }
}

@media screen and (orientation : landscape) and (min-width:981px) and (min-height:569px) {
  .Game_question__2TkJ0 {
    padding: 1rem;
    flex-direction: column;
  }

  .Game_question__2TkJ0 > img {
    width: 40vh;
    height: 40vh;
  }
}

/*
 * footer
 */

.Game_footer__mai70 {
  width: min(100%, 980px);
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.Game_footer__mai70 > button {
  --yes-border: outset 4px var(--yes-color);
  --no-border: outset 4px var(--no-color);
  --disabled-color: rgb(173, 173, 173);
  --disabled-border: outset 4px var(--panel-border-color);

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  width: 45%;
  height: min(85%, 96px);
  background-color: var(--panel-background-color);
  background-image: var(--panel-background-image);
  border: outset 4px var(--panel-border-color);
  border-radius: 5rem;
  box-shadow:
    var(--panel-box-shadow),
    inset 0 1px 2px 1px rgba(0,0,0,0.5);
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 2px 3px rgb(0,0,0);
  transition-duration: 0.3s;
}

.Game_item__zqrxU > button:focus {
  outline: none;
}

.Game_footer__mai70 > button:active,
.Game_footer__mai70 > button.Game_selected__1pKz3,
.Game_footer__mai70 > button:disabled {
  box-shadow: none;
  text-shadow: none;
}

.Game_footer__mai70 > button.Game_yes__1X6Rs {
  color: var(--yes-color);
  border: var(--yes-border);
}

.Game_footer__mai70 > button.Game_no__1JPZ6 {
  color: var(--no-color);
  border: var(--no-border);
}

.Game_footer__mai70 > button.Game_yes__1X6Rs:disabled,
.Game_footer__mai70 > button.Game_no__1JPZ6:disabled {
  color: var(--disabled-color);
  border: var(--disabled-border);
}

/*
 * overlay
 */
.Game_overlay__2uSyS {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.Game_answer__1xbFi,
.Game_lottery__z2d1w,
.Game_end__3yXDs {
  width: min(80%, 320px);
  padding: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--panel-background-color);
  background-image: var(--panel-background-image);
  border: outset 4px var(--panel-border-color);
  border-radius: 1rem;
  box-shadow:
    var(--panel-box-shadow),
    inset 0 1px 2px 1px rgba(0,0,0,0.5);
  box-sizing: border-box;
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 1px 2px rgb(0,0,0);
  -webkit-animation-name: Game_show-panel__1VthN;
          animation-name: Game_show-panel__1VthN;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  z-index: 2;
}

@-webkit-keyframes Game_show-panel__1VthN {
  from {
    transform: translateY(-40px);
    opacity:0;
  }
  to {
    transform: none;
    opacity:1;
  }
}

@keyframes Game_show-panel__1VthN {
  from {
    transform: translateY(-40px);
    opacity:0;
  }
  to {
    transform: none;
    opacity:1;
  }
}

.Game_answer__1xbFi p {
  -webkit-margin-before: 1rem;
          margin-block-start: 1rem;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.Game_answer__1xbFi img {
  display: block;
  margin: 0.5rem;
  -webkit-filter: drop-shadow(0 2px 2px rgb(0,0,0));
          filter: drop-shadow(0 2px 2px rgb(0,0,0));
}

.Game_answer__1xbFi.Game_right__3yTT2 {
  color: rgb(45, 211, 111);
}

.Game_answer__1xbFi.Game_wrong__1vyK5 {
  color: rgb(148, 66, 212);
}

.Game_lottery__z2d1w.Game_win__3eZsi {
  -webkit-animation-name: Game_show-win-panel__1-zPw;
          animation-name: Game_show-win-panel__1-zPw;
}

@-webkit-keyframes Game_show-win-panel__1-zPw {
  from {
    transform: scale(0.5) rotate(720deg);
    opacity:0;
  }
  to {
    transform: none;
    opacity:1;
  }
}

@keyframes Game_show-win-panel__1-zPw {
  from {
    transform: scale(0.5) rotate(720deg);
    opacity:0;
  }
  to {
    transform: none;
    opacity:1;
  }
}

.Game_lottery__z2d1w.Game_win__3eZsi p,
.Game_lottery__z2d1w.Game_won__3d2ET p,
.Game_lottery__z2d1w.Game_lose__3R6oJ p {
  width: 100%;
  -webkit-margin-before: 1rem;
          margin-block-start: 1rem;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.Game_lottery__z2d1w.Game_win__3eZsi p,
.Game_lottery__z2d1w.Game_won__3d2ET p {
  color: rgb(226, 50, 27);
  border-top: dotted 10px #f58e08;
  border-bottom: dotted 10px #f58e08;
}

.Game_lottery__z2d1w.Game_win__3eZsi p {
  -webkit-margin-after: 2rem;
          margin-block-end: 2rem;
}

.Game_lottery__z2d1w.Game_won__3d2ET p,
.Game_lottery__z2d1w.Game_lose__3R6oJ p {
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

.Game_lottery__z2d1w.Game_win__3eZsi p > span,
.Game_lottery__z2d1w.Game_won__3d2ET p > span {
  display: inline-block;
  font-style: normal;
  font-size: 48px;
  margin-right: 0.5rem;
}

.Game_lottery__z2d1w.Game_win__3eZsi .Game_winningNumber__2Z_R5 {
  padding-left: 1rem;
  padding-right: 1rem;
  border: solid 4px rgb(27, 136, 226);
  border-radius: 1rem;
  font-size: 2rem;
  color: rgb(27, 136, 226);
}

.Game_lottery__z2d1w.Game_lose__3R6oJ > p {
  border-top: dashed 10px rgb(27, 136, 226);
  border-bottom: dashed 10px rgb(27, 136, 226);
  color: rgb(27, 136, 226);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Game_lottery__z2d1w.Game_lose__3R6oJ p > span {
  display: inline-block;
  font-style: normal;
  font-size: 48px;
  font-size: 1.5rem;
}

.Game_end__3yXDs p {
  color: #ffab3c;
}

.Game_end__3yXDs > button {
  width: min(50vw, 200px);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Countdown_container__1u1bq {
  width: 100%;
  height: 20px;
  box-shadow:0px 0px 8px 3px #ccc inset;
  border-radius: 4px;
  overflow: hidden;
}

.Countdown_progress__KtPyU {
  background: linear-gradient(
    rgb(144, 214, 252),
    rgb(0, 165, 255),
    rgb(144, 214, 252)
  );
  height: 100%;
}
